import message from '@/helpers/message';
import { FileConstants } from '@/constants/file';

const convertMBtoKB = (size) => {
  if (!size) {
    return '';
  }
  return size * 1024 * 1024;
}

const checkFileSize = (file, maxsize = 30) => {
  if (file && file.size <= convertMBtoKB(maxsize)) {
    return true;
  }
  message.overMaxsize(maxsize);
  return false;
};

const checkCsvFile = (file) => {
  if (file && FileConstants.CSV_MINETYPES.includes(file.type)) {
    return true;
  }
  message.showError('onlyCsv');
  return false
}

const isObjectPrimitiveValues = (data) => {
  return data?.every(item => {
    return typeof item !== 'object';
  })
}

const buildFormData = (formData, data, parentKey) => {
  if (data && Array.isArray(data) && (data?.length > 0) && isObjectPrimitiveValues(data)) {
    Object.keys(data).forEach((key) => {
      formData.append(`${parentKey}[]`, data[key]);
    });
  } else if ((data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)) ||
    (Array.isArray(data) && (data?.length > 0) )
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? '' : data;
    if (!parentKey?.includes('undefined')) {
      formData.append(parentKey, value);
    }
  }
}

export {
  convertMBtoKB,
  checkFileSize,
  checkCsvFile,
  buildFormData
}
